import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  createTheme,
  ThemeProvider,
  CssBaseline,
  IconButton,
} from "@mui/material";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import ProfileCard from "./components/ProfileCard";
import { useLocation } from "react-router-dom";
import { BUTTON_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "./utils/constant";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";

const themeLight = createTheme({
  palette: {
    background: {
      default: "#f3f3f3",
    },
  },
  typography: {
    fontFamily: [
      "Nunito",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

const App: React.FC = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<string>("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (location.state?.scrollTo === "projects") {
      const projectSection = document.getElementById("projects");
      if (projectSection) {
        window.scrollTo({
          top: projectSection.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [location]);
  // Fonction personnalisée pour scroller en douceur
  const smoothScrollTo = (targetY: number, duration: number = 800) => {
    const startY = window.scrollY;
    const distance = targetY - startY;
    let startTime: number | null = null;

    const scroll = (currentTime: number) => {
      if (!startTime) startTime = currentTime;
      const elapsedTime = currentTime - startTime;

      const progress = Math.min(elapsedTime / duration, 1); // Limite entre 0 et 1
      const easeInOutQuad =
        progress < 0.5
          ? 2 * progress * progress
          : -1 + (4 - 2 * progress) * progress; // Fonction easing pour un effet plus fluide

      window.scrollTo(0, startY + easeInOutQuad * distance);

      if (elapsedTime < duration) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const targetPosition = section.offsetTop;
      smoothScrollTo(targetPosition);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "skills", "projects", "contact"];
      const scrollPosition = window.scrollY + 200; // Ajouter un offset pour compenser la taille de la barre de navigation

      // Parcours des sections pour déterminer celle actuellement visible
      for (const section of sections) {
        const element = document.getElementById(section);
        if (
          element &&
          element.offsetTop <= scrollPosition &&
          element.offsetTop + element.offsetHeight > scrollPosition
        ) {
          setActiveSection(section);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Si le défilement dépasse 50px, changer la couleur de l'AppBar
      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          backgroundColor: scrolled ? SECONDARY_COLOR : PRIMARY_COLOR, // Changer de couleur lorsque la page est défilée
          boxShadow: scrolled ? "0px 4px 10px rgba(0,0,0,0.3)" : "none", // Ajouter une ombre quand l'AppBar devient opaque
          transition: "background-color 0.3s ease", // Transition fluide pour le changement de couleur
        }}
        elevation={0}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, paddingLeft: 5 }}
          >
            Portfolio
          </Typography>
          <Box sx={{ flexGrow: 0, paddingRight: 3 }}>
            <Button
              color="inherit"
              href="#home"
              sx={{
                backgroundColor:
                  activeSection === "home" ? BUTTON_COLOR : "inherit",
              }}
              onClick={() => scrollToSection("home")}
            >
              Accueil
            </Button>
            {/* <Button
              color="inherit"
              href="#skills"
              sx={{
                backgroundColor:
                  activeSection === "skills" ? BUTTON_COLOR : "inherit",
              }}
              onClick={() => scrollToSection("skills")}
            >
              Skills
            </Button> */}
            <Button
              color="inherit"
              href="#projects"
              sx={{
                backgroundColor:
                  activeSection === "projects" ? BUTTON_COLOR : "inherit",
              }}
              onClick={() => scrollToSection("projects")}
            >
              Projets
            </Button>
            <Button
              color="inherit"
              href="#contact"
              sx={{
                backgroundColor:
                  activeSection === "contact" ? BUTTON_COLOR : "inherit",
              }}
              onClick={() => scrollToSection("contact")}
            >
              Contact
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box my={4} id="home" sx={{ paddingTop: "0.1px" }}>
        <ProfileCard />
      </Box>
      {/* <Box my={4} id="skills" sx={{ paddingTop: "64px", marginTop: "-64px" }}>
        <Skills />
      </Box> */}
      <Box my={4} id="projects" sx={{ paddingTop: "64px", marginTop: "-64px" }}>
        <Projects />
      </Box>
      <Box my={4} id="contact" sx={{ paddingTop: "64px", marginTop: "-64px" }}>
        <Contact />
      </Box>
      {/* Icônes des réseaux sociaux en bas */}
      <Box
        sx={{
          bottom: 16,
          left: "50%",
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          color="inherit"
          component="a"
          href="https://www.linkedin.com/in/hobisoa-andromalala-139b20168/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="inherit"
          component="a"
          href="https://github.com/andromalala"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="inherit"
          component="a"
          href="https://web.facebook.com/andromalalahobisoa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon fontSize="large" />
        </IconButton>
      </Box>
    </ThemeProvider>
  );
};

export default App;
