import React from "react";
import { Card, CardContent, Grid, Typography, CardMedia } from "@mui/material";
import { PRIMARY_COLOR } from "../utils/constant";

const ProfileCard: React.FC = () => {
  return (
    <Card
      sx={{
        display: "flex",
        width: "98.8vw",
        height: "100vh",
        backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 400,
              height: 400,
              borderRadius: "15%",
              objectFit: "cover",
            }}
            image={require("../assets/profile.png")}
            alt="Hobisoa"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent sx={{ textAlign: "initial" }}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              color={"white"}
              sx={{
                fontWeight: 900,
                fontFamily: "Helvetica Neue ",
              }}
            >
              Hello / Bonjour / Salama
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              color={"white"}
              marginBottom={5}
              sx={{
                fontWeight: 900,
                fontFamily: "Helvetica Neue",
              }}
            >
              I'm Andromalala Harinaivo Hobisoa Emile
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              color={"white"}
              sx={{ textAlign: "justify" }}
            >
              Développeur d'applications mobiles avec plus de 4 ans d'expérience
              au sein de diverses entreprises, j'ai acquis une solide expertise
              dans la création de solutions performantes et innovantes. Capable
              de travailler efficacement en équipe comme de manière autonome, je
              suis engagé à livrer des produits de haute qualité, tout en
              respectant les délais et les besoins des utilisateurs. Mon
              expérience couvre l'ensemble du cycle de développement, de la
              conception à la mise en production, avec une attention
              particulière aux détails et à l'optimisation des performances.
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileCard;
