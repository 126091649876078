import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  CardMedia,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BG_BODY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../utils/constant";
import { projects } from "../Projects";

const sections = [
  { title: "Arrière-plan", description: "Description de Arrière-plan" },
  { title: "Problème", description: "Description de Problème" },
  { title: "Objectif", description: "Description de Objectif" },
  { title: "Mon impact", description: "Description de Mon impact" },
];

const MyComponent: React.FC = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const { projectId } = useParams<{ projectId: string }>(); // Récupérer l'ID depuis les paramètres d'URL
  console.log("projectId" + projectId);

  const project = projects.find((p) => p.id === parseInt(projectId!));

  const goHome = () => {
    navigate("/", { state: { scrollTo: "projects" } });
  };

  useEffect(() => {
    document.body.style.backgroundColor = BG_BODY; // Changez la couleur selon vos besoins

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Si le défilement dépasse 50px, changer la couleur de l'AppBar
      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" }); // Scroll to top of the page
  }, []);
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: BG_BODY, // Changer de couleur lorsque la page est défilée
          boxShadow: scrolled ? "0px 4px 10px rgba(0,0,0,0.3)" : "none", // Ajouter une ombre quand l'AppBar devient opaque
          transition: "background-color 0.3s ease", // Transition fluide pour le changement de couleur
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            sx={{
              color: "black", // Changer de couleur lorsque la page est défilée
            }}
            aria-label="back"
            onClick={goHome}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              color: "black", // Changer de couleur lorsque la page est défilée
            }}
          >
            Retour
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 10, paddingLeft: 8 }}>
        <Typography
          variant="h3"
          sx={{ fontFamily: "Helvetica Neue", color: SECONDARY_COLOR }}
        >
          {project?.title}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ marginTop: "1rem", marginBottom: "1.5rem" }}
        >
          {project?.description}
        </Typography>
        <Typography variant="body2" sx={{ color: "#666" }}>
          <strong>Role: </strong>
          {project?.role}
        </Typography>

        <Typography variant="body2" sx={{ color: "#666" }}>
          <strong>Année: </strong> {project?.year}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#666", marginBottom: "3rem" }}
        >
          <strong>Téchnologie: </strong> {project?.techno}
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ paddingLeft: 50 }}>
        <Grid item xs={6}>
          <Paper
            style={{ padding: 16 }}
            sx={{
              backgroundColor: "transparent",
            }}
            elevation={0}
          >
            <Typography
              variant="h5"
              sx={{ color: PRIMARY_COLOR, marginBottom: "1rem" }}
            >
              Objectif
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: "2rem", lineHeight: 1.8 }}
            >
              {project?.objectif}
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: PRIMARY_COLOR, marginBottom: "1rem" }}
            >
              Mon impact
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: "2rem", lineHeight: 1.8 }}
            >
              {project?.impact}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <CardMedia
            component="img"
            sx={{
              width: 600,
              height: 500,
              borderRadius: "7%",
              objectFit: "contain",
            }}
            image={project?.photo}
            alt="Hobisoa"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MyComponent;
