import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Grid, Link, Button, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../utils/constant";

const Contact: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.3 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef?.current);
      }
    };
  }, []);

  return (
    <Box
      my={4}
      textAlign="initial"
      sx={{
        paddingLeft: 11,
        paddingRight: 40,
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(100px)",
      }}
      ref={containerRef}
    >
      <Typography
        variant="h4"
        textAlign="initial"
        mb={3}
        color={PRIMARY_COLOR}
        sx={{
          fontWeight: 900,
          fontFamily: "Helvetica Neue ",
        }}
      >
        Contact
      </Typography>
      <Typography gutterBottom>
        Contactez-moi ou envoyez-moi un email directement à
        hobisoaandromalala@gmail.com
      </Typography>
      {/* <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <EmailIcon />
          <Typography variant="body1">
            <Link href="mailto:hobisoaandromalala@gmail.com">
              hobisoaandromalala@gmail.com
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <PhoneIcon />
          <Typography variant="body1">+261 34 45 498 69</Typography>
        </Grid>
        <Grid item>
          <LocationOnIcon />
          <Typography variant="body1">Lot IVV 17 bis Ankazomanga</Typography>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First"
            required
            fullWidth
            variant="outlined"
            placeholder="First"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last"
            required
            fullWidth
            variant="outlined"
            placeholder="Last"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Email"
            required
            fullWidth
            variant="outlined"
            placeholder="Email"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            placeholder="Enter your message"
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, backgroundColor: SECONDARY_COLOR }}
          >
            Envoyer
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
