import React, { useRef, useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardMedia, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../utils/constant";

export const projects = [
  {
    id: 1,
    title: "MyClicar",
    description:
      "Application mobile conçue pour simplifier et digitaliser la gestion des contrats et des états des lieux de véhicules",
    client: "Clicar",
    image: require("../assets/clicar_logo.png"),
    role: "Responsable du développement d'application mobile",
    year: "2022 - présent",
    techno: "Flutter, Dart, Node Js, Mongo DB",
    photo: require("../assets/myclicar/1.jpeg"),
    objectif:
      "Evise à offrir une solution efficace pour automatiser les processus administratifs liés aux transactions automobiles, tels que la création, la gestion et la signature des contrats, ainsi que l'enregistrement des états des lieux des véhicules. Cela permet aux utilisateurs de gagner du temps, de réduire les erreurs et de centraliser toutes les informations importantes dans une plateforme numérique accessible et sécurisée.",
    impact:
      "En tant que responsale de l'application mobile, je réside dans ma capacité à assurer une livraison fluide de l'application sur la plateforme iOS et android, tout en collaborant efficacement avec l'équipe pour garantir la qualité, l'optimisation et la conformité de l'application, contribuant ainsi à la réussite globale du projet.",
  },
  {
    id: 2,
    title: "Tolotra.mg",
    description:
      "Application de petites annonces qui permet aux utilisateurs de publier, rechercher et consulter des offres dans diverses catégories",
    client: "FlitSoft",
    image: require("../assets/tolotra_logo.png"),
    role: "Développeur",
    year: "2020",
    techno: "Flutter, Dart, Node Js, Firebase",
    photo: require("../assets/tolotra/1.jpg"),
    objectif:
      "Fournir une plateforme accessible où les utilisateurs peuvent publier, consulter, et interagir avec des annonces classées dans diverses catégories (immobilier, véhicules, emplois, services, etc.). L'application facilite la mise en relation entre acheteurs et vendeurs, simplifie la recherche d'opportunités, et permet des transactions directes, tout en offrant une interface conviviale pour gérer les annonces, les échanges, et les filtres de recherche.",
    impact:
      "J'intégre efficacement une application de petites annonces tout en apprenant et en m'adaptant aux meilleures pratiques de développement mobile. Travaillant en équipe, j'apporte ma contribution en collaborant activement, en partageant mes idées, et en participant à la résolution des défis techniques. L'utilisation de Git me permet de gérer le versioning du code de manière structurée, de faciliter la collaboration avec mes coéquipiers et de garantir une livraison fluide des nouvelles fonctionnalités tout en minimisant les conflits de code.",
  },
  {
    id: 3,
    title: "FlitRide",
    description:
      "Application de mobilité qui connecte les passagers et les chauffeurs de taxi à Madagascar",
    client: "FlitSoft",
    image: require("../assets/flitride_logo.png"),
    role: "Développeur",
    year: "2021 - 2022",
    techno: "Flutter, Dart, Node Js, Firebase",
    photo: require("../assets/flitride/1.jpg"),
    objectif:
      "Grâce à l’application mobile Flit Ride, les chauffeurs peuvent désormais demander une course en appuyant sur un bouton de leur smartphone et faire des offres de prix sur un marché libre. Une fois qu’un passager est jumelé à un chauffeur, la plateforme traitera le paiement du chauffeur qui pourra se faire soit par l’intermédiaire de mobile money, soit par des jetons Flit ou encore par carte de crédit.",
    impact:
      "J'ai joué un rôle essentiel dans la création d'une plateforme intuitive et performante, offrant une expérience utilisateur fluide pour les chauffeurs et les passagers. De plus, j'ai mis en place des tests unitaires rigoureux pour assurer la qualité du code, permettant ainsi une détection rapide des bugs et assurant une application stable et évolutive.",
  },
  {
    id: 4,
    title: "PockerApply",
    description: "Intégration du module don et partenariat",
    client: "Freelance",
    image: require("../assets/pa_logo.png"),
    role: "Développeur",
    year: "2024",
    techno: "React native, Node js, Mongo DB",
    photo: require("../assets/poker/1.png"),
    objectif:
      "Faciliter les dons et de promouvoir les partenariats entre individus ou organisations. Elle vise à offrir une plateforme simple et accessible où les utilisateurs peuvent soutenir des causes qui leur tiennent à cœur par des dons, tout en encourageant la collaboration à travers des partenariats.",
    impact:
      "En tant que développeur mobile, l'intégration du module PockerApply dans mon premier projet professionnel en React Native démontre ma capacité à concevoir et implémenter des fonctionnalités complexes, telles que la gestion des dons et des partenariats, l'implémenntation du paiement stripe tout en garantissant une expérience utilisateur fluide et sécurisée,",
  },
];

const Projects: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const handleDetailsClick = (projectId: number) => {
    navigate(`/detailProject/${projectId}`);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.3 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef?.current);
      }
    };
  }, []);

  return (
    <Box my={4} pl={8} pr={8}>
      <Typography
        variant="h4"
        textAlign="initial"
        mb={3}
        pl={3}
        color={PRIMARY_COLOR}
        sx={{
          fontWeight: 900,
          fontFamily: "Helvetica Neue ",
        }}
      >
        Projets
      </Typography>
      <Grid container spacing={4} ref={containerRef}>
        {projects.map((project, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                position: "relative",
                overflow: "hidden",
                transition: "transform 0.5s, opacity 0.5s",
                padding: "16px",
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(100px)",
                marginX: "20px", // Ajout de la marge sur les côtés
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <CardMedia
                component="img"
                image={project.image}
                title={project.title}
                sx={{ height: 300 }} // Définissez la hauteur souhaitée ici
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "left ",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.7)", // Fond semi-transparent
                  opacity: hoveredIndex === index ? 1 : 0,
                  transition: "opacity 0.3s",
                  zIndex: 3,
                  padding: 8,
                }}
              >
                <Typography variant="h6">{project.title}</Typography>
                <Typography variant="body2" color="inherit">
                  {project.description}
                </Typography>
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{ fontWeight: 900 }}
                >
                  Client: {project.client}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, backgroundColor: SECONDARY_COLOR, mr: 15 }}
                  onClick={() => handleDetailsClick(project.id)}
                >
                  En savoir plus
                </Button>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.3)", // Fond semi-transparent
                  opacity: hoveredIndex === index ? 0 : 1,
                  transition: "opacity 0.3s",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.3)", padding: 1 }}
                >
                  {project.title}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Projects;
